<template>
    <section class="notifications">
        <div class="row-fluid head title">
            <h1>Emails & Notifications</h1>
        </div>
        <section v-if="error">
            <i class="symbol-warning"></i> {{error}}
        </section>

        <loading-message v-if="loading" message="Loading your alerts and notifications…"/>
        <section v-else-if="!loading && !brands.length">
            <h5>Your account has no brands</h5>
            <p>
                Please add brands and search phrases in order to set up alerts.
            </p>
        </section>
        <section v-else class="notifications-loaded">
            <section v-if="!alertsEnabled">
                <staff-info-card>
                    <p>
                        This account has no brands on <em>Risk & Reputation</em>,
                        and so does not have Alerts enabled.
                    </p>
                </staff-info-card>

            </section>
            <section v-else>
                <section v-if="alertBrands.length" class="notifications__section">
                    <h4>Alerts</h4>
                    <p>
                        If you would like to receive emails when we detect unusually high levels in conversations
                        around the following themes, you can subscribe yourself below.
                    </p>

                    <!-- The following sections allow staff to more quickly see why the default alerts are not available. -->
                    <section v-if="!hasOwnedAndActiveSegments && user.admin" class="notifications__section">
                        <staff-info-card>
                            <p>
                                This account does not have any Owned brands with active segment lists,
                                so there will be limited default alerts available.
                            </p>
                        </staff-info-card>
                    </section>

                    <section class="notifications__controls">
                        <label>
                            <input type="checkbox" v-model="seeOnlySubscribed" @click="scrollIntoView">
                            <span>See only alerts I'm subscribed to</span>
                        </label>
                        <label>
                            <input type="checkbox" v-model="hideInactive">
                            <span>Hide inactive alerts</span>
                        </label>
                    </section>

                    <section v-for="brand in alertBrands"
                             :key="brand.id"
                             class="notifications__spike-area"
                             :class="{'notifications__spike-area--expanded': !!defaultsExpanded.length}">
                        <dotted-card>
                            <h4>{{formatBrandName(brand)}}</h4>
                            <p>
                                Get email updates for <em>{{formatBrandName(brand)}}</em>
                            </p>
                        </dotted-card>

                        <alert-notification v-for="n in getAlertsForBrand(brand)"
                                            @expanded="handleSpikeExpansion(n, $event)"
                                            :key="n.id"
                                            :notification="n"
                                            :logs="getLogsforAlert(n)"/>

                        <dotted-card v-if="!getAlertsForBrand(brand).length" class="notifications--none">
                            <p>
                                You are not subscribed to any alerts for {{formatBrandName(brand)}}
                            </p>
                        </dotted-card>
                    </section>
                </section>

                <section v-if="!hasOwnedBrand && user.admin" class="notifications__section">
                    <staff-info-card>
                        <h4>Brands not set up</h4>
                        <p>
                            This account has no brands set up as Owned brands, so will not
                            have any default alerts available.
                        </p>
                    </staff-info-card>
                </section>
            </section>


            <section class="notifications__section" v-if="alertsEnabled || customAlertsOverride">
                <h4>Custom Alerts</h4>
                <p>
                    If you would like to be alerted when we detected unusual conversations around something specific
                    that you are interested in, you can set up your own custom alerts here.
                </p>

                <section v-if="volumeSpike.length || seeOnlySubscribed" class="notifications__controls">
                    <be-button primary
                               @click="createNewVolumeSpike"
                               :disabled="!!creatingSpike || !canEditNotifications">
                        <i class="symbol-add"></i>Create a new custom alert
                    </be-button>
                    <label>
                        <input type="checkbox" v-model="seeOnlySubscribed" @click="scrollIntoView">
                        <span>See only custom alerts I'm subscribed to</span>
                    </label>
                    <label>
                        <input type="checkbox" v-model="hideInactive">
                        <span>Hide inactive alerts</span>
                    </label>
                </section>

                <section class="notifications__spike-area" :class="{'notifications__spike-area--expanded': !!customSpikesExpanded.length || creatingSpike}">
                    <alert-notification v-for="n in volumeSpike"
                                        :key="n.id"
                                        @expanded="handleCustomSpikeExpansion(n, $event)"
                                        @created="spikeCreated($event)"
                                        @creation-cancelled="spikeCreationCancelled"
                                        :notification="n"
                                        :logs="getLogsforAlert(n)"/>

                    <dotted-card v-if="creatingSpike">
                        <p>You're busy creating a notification.</p>
                    </dotted-card>
                </section>
                <dotted-card v-if="!volumeSpike.length && !seeOnlySubscribed && !creatingSpike && canEditNotifications"
                             @click="createNewVolumeSpike()"
                             class="notifications__blank">
                    <h1>Create a custom alert</h1>
                    <p>
                        Add a new alert, customised to your own needs.
                    </p>
                </dotted-card>
                <dotted-card v-if="!volumeSpike.length && seeOnlySubscribed" class="notifications__dotted-none">
                    <p>
                        <em>You are not subscribed to any custom alerts</em>
                    </p>
                </dotted-card>
            </section>



            <section class="notifications__section">
                <h4>Weekly summaries</h4>
                <p>
                    <em>Weekly summaries</em> are emails that summarise
                    activity on your brands for the last week.
                </p>
                <p v-if="!brands.length">
                    <slotted-tag no-close><i class="symbol-info"></i></slotted-tag> Your account currently has not been set up with any brands. Please contact our support team.
                </p>
                <section v-if="brandSummary.length || seeOnlySubscribed" class="notifications__controls">
                    <be-button primary
                               @click="createNewBrandSummary"
                               :disabled="!!creatingBrandSummary || !canEditNotifications">
                        <i class="symbol-add"></i>Create new weekly summary
                    </be-button>
                    <label>
                        <input type="checkbox" v-model="seeOnlySubscribed" @click="scrollIntoView">
                        <span>See only my subscriptions</span>
                    </label>
                    <label>
                        <input type="checkbox" v-model="hideInactive">
                        <span>Hide inactive summaries</span>
                    </label>
                    <label v-if="brandSummaryWarnings.length > 0"
                           @click="$refs.brandSummaryWarnings.showWarnings()">
                        <warning-indicator ref="brandSummaryWarnings"
                                           :warnings="brandSummaryWarnings"
                                           @warning-clicked="navigateTo($event)"
                                           tooltip="Some of the weekly summaries below have warnings"/>
                        <span>
                            <deq-number :number="brandSummaryWarnings.length"/>
                            {{formatPlural(brandSummaryWarnings.length, "weekly summary has", "weekly summaries have")}} warnings
                        </span>
                    </label>
                </section>
                <section v-if="brandSummary.length || seeOnlySubscribed"
                         class="notifications__header">
                    <span>Name</span>
                    <span>Subscribed</span>
                    <span>Recipients</span>
                    <span class="notifications__hide-small">Last updated</span>
                </section>
                <SetupNotificationRow v-for="n in brandSummary"
                                      :key="n.id"
                                      :notification="n"
                                      :focus="focusedId === n.id"
                                      :data-notification-id="n.id"
                                      @update-warnings="checkForWarnings()"
                                      @created="brandSummaryCreated($event)"
                                      @creation-cancelled="brandSummaryCreationCancelled"/>
                <section v-if="!brandSummary.length && seeOnlySubscribed"
                         class="notifications__not-subscribed">
                    You are not subscribed to any weekly summaries
                </section>

                <dotted-card v-if="brands.length && !brandSummary.length && !seeOnlySubscribed && !creatingBrandSummary && canEditNotifications"
                             @click="createNewBrandSummary"
                             class="notifications__blank">
                    <h1>Subscribe to a weekly summary</h1>
                    <p>
                        Receive regular emails that show you how your brands are doing.
                    </p>
                </dotted-card>
            </section>

            <section class="notifications__section">
                <h4>Press summaries</h4>
                <p v-if="!hasTraditionalMedia">
                    <em>Press summaries</em> are regular emails highlighting news from online press sources.
                </p>
                <p v-else>
                    <em>Press summaries</em> are regular emails highlighting news from online press sources, as well as traditional media.
                </p>
                <section v-if="pressNotifications.length || seeOnlySubscribed" class="notifications__controls">
                    <be-button primary
                               @click="createNewPressDigest"
                               :disabled="!!creatingPressDigest || !canEditNotifications">
                        <i class="symbol-add"></i>Create new press summary
                    </be-button>
                    <label>
                        <input type="checkbox" v-model="seeOnlySubscribed" @click="scrollIntoView">
                        <span>See only my subscriptions</span>
                    </label>
                    <label>
                        <input type="checkbox" v-model="hideInactive">
                        <span>Hide inactive summaries</span>
                    </label>
                    <label v-if="pressWarnings.length"
                           @click="$refs.pressWarnings.showWarnings()">
                        <warning-indicator :warnings="pressWarnings"
                                           ref="pressWarnings"
                                           @warning-clicked="navigateTo($event)"
                                           tooltip="Some of the weekly summaries below have warnings"/>
                        <span>
                            <deq-number :number="pressWarnings.length"/> {{formatPlural(pressWarnings.length, "press summary has", "press summaries have")}} warnings
                        </span>
                    </label>
                </section>
                <section v-if="pressNotifications.length || seeOnlySubscribed"
                         class="notifications__header">
                    <span>Name</span>
                    <span>Subscribed</span>
                    <span>Recipients</span>
                    <span class="notifications__hide-small">Last updated</span>
                </section>
                <SetupNotificationRow v-for="n in pressNotifications"
                                      :key="n.id"
                                      :notification="n"
                                      :focus="focusedId === n.id"
                                      :data-notification-id="n.id"
                                      @update-warnings="checkForWarnings()"
                                      @created="pressDigestCreated($event)"
                                      @creation-cancelled="pressCreationCancelled"/>
                <section v-if="!pressNotifications.length && seeOnlySubscribed"
                         class="notifications__not-subscribed">
                    You are not subscribed to any press summaries
                </section>

                <dotted-card v-if="!pressNotifications.length && !seeOnlySubscribed && !creatingPressDigest && canEditNotifications"
                             @click="createNewPressDigest"
                             class="notifications__blank">
                    <h1>Subscribe to a press summary</h1>
                    <p v-if="!hasTraditionalMedia">
                        Receive a weekly email summarising news from online press sources.
                    </p>
                    <p v-else>
                        Receive a weekly email summarising news from online press sources as well as traditional media.
                    </p>
                </dotted-card>
            </section>


            <section class="notifications__section">
                <h4>Mention & dashboard notifications</h4>
                <p>
                    Notifications send an email when we find mentions that interest you.
                    You can also schedule a dashboard to be sent via email.
                    Use this to track conversations around your brands or online profiles, or topics that you want to keep an eye on.
    
                </p>
                <section v-if="regularNotifications.length || seeOnlySubscribed"
                         class="notifications__controls">
                    <be-button primary @click="createNewNotification" :disabled="!!creating || !canEditNotifications">
                        <i class="symbol-add"></i>Create new notification
                    </be-button>
                    <label>
                        <input type="checkbox" v-model="seeOnlySubscribed" @click="scrollIntoView">
                        <span>See only my subscriptions</span>
                    </label>
                    <label>
                        <input type="checkbox" v-model="hideInactive">
                        <span>Hide inactive notifications</span>
                    </label>
                    <label v-if="regularWarnings.length > 0"
                           @click="$refs.regularWarnings.showWarnings()">
                        <warning-indicator ref="regularWarnings"
                                           :warnings="regularWarnings"
                                           @warning-clicked="navigateTo($event)"
                                           tooltip="Some of the notifications below have warnings"/>
                        <span>
                            <deq-number :number="regularWarnings.length"/> {{formatPlural(regularWarnings.length, "notification has", "notifications have")}} warnings
                        </span>
                    </label>

                </section>

                <section v-if="regularNotifications.length || seeOnlySubscribed"
                         class="notifications__header">
                    <span>Name</span>
                    <span>Subscribed</span>
                    <span>Recipients</span>
                    <span class="notifications__hide-small">Last updated</span>
                </section>
                <section v-if="!regularNotifications.length && seeOnlySubscribed"
                         class="notifications__not-subscribed">
                    You are not subscribed to any notifications
                </section>

                <SetupNotificationRow v-for="n in regularNotifications"
                                      :key="n.id"
                                      :notification="n"
                                      :focus="focusedId === n.id"
                                      :data-notification-id="n.id"
                                      @update-warnings="checkForWarnings()"
                                      @created="notificationCreated($event)"
                                      @creation-cancelled="creationCancelled"/>
                <dotted-card v-if="!regularNotifications.length && !creating && !seeOnlySubscribed && canEditNotifications"
                             @click="createNewNotification"
                             class="notifications__blank">
                    <h1>Add a notification</h1>
                    <p>
                        Using our email notifications, you can be emailed whenever we receive mentions that match
                        a particular filter.
                    </p>
                </dotted-card>
            </section>
        </section>
    </section>
</template>

<script>
import BeButton from "@/components/buttons/BeButton";
import LoadingMessage from "@/components/LoadingMessage";
import WarningIndicator from "@/components/WarningIndicator";
import DottedCard from "@/components/cards/DottedCard";
import {beef, mash} from "@/store/Services";
import {notifyWithText} from "@/app/framework/notifications/Notifications";
import SlottedTag from "@/components/tags/SlottedTag";
import StaffInfoCard from "@/components/cards/StaffInfoCard";
import {getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import {editDigests} from "@/app/Permissions";
import {showTip} from "@/app/help/tips/tips";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapState} from "vuex";
import {isSubscribed} from "@/setup/notifications/SetupNotificationUtilities";
import {formatBrandName, formatPlural} from "@/app/utils/Format";
import {escapeExpression} from "@/app/utils/StringUtils";
import {setTitle} from "@/app/Beef";
import {getNotificationWarnings} from "@/store/vuex/dataChecks";
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
    store: VuexStore,
    components: {
        DeqNumber,
        StaffInfoCard,
        SlottedTag,
        DottedCard,
        WarningIndicator,
        LoadingMessage,
        AlertNotification: () => import('./AlertNotification'),
        BeButton,
        SetupNotificationRow: () => import('./SetupNotificationRow')
    },

    props: {
        showMine: Boolean,
        showNotificationId: Number
    },

    data() {
        return {
            canEditNotifications: editDigests(),
            alertsEnabled: false,
            customAlertsOverride: false,
            error: null,
            notifications: [],
            loading: false,
            creating: false,
            creatingSpike: false,
            creatingBrandSummary: false,
            creatingPressDigest: false,
            seeOnlySubscribed: !!this.showMine,
            hideInactive: true,
            cancelNotification: {
                type: "SPIKE_ALERT",
                filter: "published on today and reshareOf is unknown"
            },
            defaultSpikeNotifications: [],
            defaultsForBrands: {},
            defaultsExpanded: [],
            customSpikesExpanded: [],
            regularWarnings: [],
            brandSummaryWarnings: [],
            pressWarnings: [],
            hasOwnedBrand: false,
            hasOwnedAndActiveSegments: false,
            alertLogs: [],
            hasTraditionalMedia: null,
            focusedId: this.showNotificationId
        }
    },

    watch: {
        seeOnlySubscribed(val) {
            this.focusedId = null;
            this.updateUrl();
        },

        focusedId() {
            this.updateUrl();
        },

        showNotificationId() {
            this.focusedId = this.showNotificationId
        }
    },

    computed: {
        ...mapState(['account', 'user']),
        ...mapState({
            brands: state => state.rootBrands,
        }),
        ...mapGetters(['idToTag']),
        ...mapState('digests', { digestStore: 'digests'}),
        ...mapGetters('digests', ['idToDigest']),

        alertBrands() {
            return this.brands.filter(b => b.category === "OWN" && this.getAlertsForBrand(b).length);
        },
        brandSummary() {
            return this.notifications
                .filter(m => m.type === "BRAND_SUMMARY" && this.isVisible(m))
                .map(b => b.id ? this.idToDigest.get(b.id) : b);
        },
        pressNotifications() {
            return this.notifications
                .filter(m => m.type === "TRADITIONAL_MEDIA" && this.isVisible(m))
                .map(b => b.id ? this.idToDigest.get(b.id) : b);
        },
        volumeSpike() {
            return this.notifications
                .filter(m => m.type === "SPIKE_ALERT" && !m.externalId && this.isVisible(m))
                .map(b => b.id ? this.idToDigest.get(b.id) : b);
        },

        regularNotifications() {
            return this.notifications
                .filter(m => m.type !== "SPIKE_ALERT"
                    && m.type !== "BRAND_SUMMARY"
                    && m.type !== "TRADITIONAL_MEDIA"
                    && this.isVisible(m))
                .map(b => b.id ? this.idToDigest.get(b.id) : b);
        }
    },

    async created() {
        this.hasTraditionalMedia = this.account.hasFeedBroadcast || this.account.hasFeedPrint;
    },

    async mounted() {
        setTitle("Notifications");
        this.updateUrl();
        try {
            // We don't want issues with loading data to perculate up and stop the
            // reset of the code here.
            await this.load();
        } catch(e) {
            console.error(e);
        }

        this.checkForWarnings().catch(e => console.error(e));

        // See if we've been asked to immediately be editing a new notification.
        if (window.notificationData) {
            this.createNewNotification(null, window.notificationData.filter);
            notifyWithText("Your notification is ready to edit", null, '<i class="symbol-notification"></i>');
            delete window.notificationData;
            this.$nextTick(() => {
                // Get the first expanded query
                const query = document.querySelector('.notification-row.expanded');
                query.scrollIntoView({ behavior: 'smooth', block: 'center' })

            });
        }

        if (this.focusedId) {
            const focused = this.notifications.find(n => n.id === this.focusedId);
            if (!focused) {
                this.focusedId = null;
            } else if (!focused.active) {
                this.hideInactive = false;
            }
        }

        await this.refreshAccountUsers();

        showTip("NOTIFICATIONS_ALERTS", "NOTIFICATIONS_WEEKLY", "NOTIFICATIONS_DASHBOARDS");
    },

    methods: {
        ...mapActions(['refreshBrands', 'refreshTags']),
        ...mapActions('digests', ['refreshDigests', 'deleteDigest', 'updateDigest', 'updateNotificationFields']),
        ...mapActions('accountUsers', ['refreshAccountUsers']),

        formatBrandName,
        formatPlural,

        scrollIntoView(event) {
            setTimeout(() => event.target.scrollIntoView({behavior: "smooth", block: "center"}), 100)
        },

        getAlertsForBrand(brand) {
            if (!this.defaultsForBrands[brand.id]) {
                this.$set(this.defaultsForBrands, brand.id, []);
            }
            return this.defaultsForBrands[brand.id]
                .filter(a => this.isVisible(a))
                .map(b => b.id ? this.idToDigest.get(b.id) : b);
        },

        getLogsforAlert(alert) {
            if (!this.alertLogs?.length) return [];
            return this.alertLogs
                .filter(log => log?.metadata?.notificationId === alert.id)
        },

        handleSpikeExpansion(notification, expanded) {
            // Use object identity here instead of the notification id since
            // default notifications may not have an id if they haven't yet been created.
            if (expanded) {
                this.defaultsExpanded.push(notification.id);
            } else {
                this.defaultsExpanded = this.defaultsExpanded.filter(n => n !== notification.id);
            }
        },

        handleCustomSpikeExpansion(notification, expanded) {
            if (!notification?.id) return;
            if (expanded) {
                this.customSpikesExpanded.push(notification.id);
            } else {
                this.customSpikesExpanded = this.customSpikesExpanded.filter(id => id !== notification.id);
            }

            if (this.creatingSpike && !this.creatingSpike._new) {
                this.creatingSpike = false;
            }
        },

        updateUrl() {
            if (this.seeOnlySubscribed || !this.focusedId) {
                Beef.router.navigate(`/${this.account.code}/setup/notifications${this.seeOnlySubscribed ? '/mine' : ''}`, {trigger: false});
            } else {
                Beef.router.navigate(`/${this.account.code}/setup/notifications/${this.focusedId}`, {trigger: false});
            }

        },

        /** Useful for finding out when alerts fired. This is stored in the logs. */
        async fetchLogs() {
            const alerts = await mash.get(`/rest/accounts/${this.account.code}/activity?type=ALERT_DIGEST&limit=100`);
            this.alertLogs = alerts.data.data;
        },

        async load() {
            this.loading = true;
            try {
                this.fetchLogs().catch(e => console.error('Error fetching logs for alerts', e));

                // This is important. Default alerts don't exist until someone creates them. This
                // endpoint is being called to figure out what alerts have not been created yet.
                // It also ensures that if default alerts change, that the alerts are kept up to
                // date.
                const response = await beef.get(`/api/accounts/${this.account.code}/notifications/missing`);
                const missingDefaults = response.data;
                await this.refreshDigests(true);
                this.notifications = Array.from(this.digestStore);
                missingDefaults.forEach(n => this.notifications.push(n));
                await this.refreshBrands();
                await this.refreshTags();

                const brands = this.brands;

                this.alertsEnabled = brands.some(b => {
                    if (b.crowdSamplePercentage < 100 || !b.activeSegmentListIds) {
                        return false;
                    }

                    for (const id of b?.activeSegmentListIds) {
                        const tag = this.idToTag.get(id);
                        if (tag.segmentType?.id === "CONDUCT_LIST") {
                            return true;
                        }
                    }
                    return false;
                })

                this.customAlertsOverride = this.account.customAlertsEnabled;

                // See if this account is able to have alerts
                this.hasOwnedBrand = !!brands.find(b => b.category === "OWN");
                this.hasOwnedAndActiveSegments = !!brands.find(b => b.category === "OWN" && b.activeSegmentListIds?.length);

                // Find any previously setup cancellation notifications.
                let risk = this.notifications.filter(n => n?.externalId?.endsWith(":risk"));
                let purchase = this.notifications.filter(n => n?.externalId?.endsWith(":purchase"));
                let cancel = this.notifications.filter(n => n?.externalId?.endsWith(":cancel"));
                let service = this.notifications.filter(n => n?.externalId?.endsWith(":service"));
                let sentiment = this.notifications.filter(n => n?.externalId?.endsWith(":sentiment"));

                [...risk, ...purchase, ...cancel, ...service, ...sentiment].forEach(d => {
                    if (d.deleted) return;

                    const split = d.externalId.split(':');
                    if (split.length === 3) {
                        const id = parseInt(split[0]);
                        this.$set(this.defaultsForBrands, id, this.defaultsForBrands[id] ?? []);
                        this.defaultsForBrands[id].push(d);
                    } else {
                        this.defaultSpikeNotifications.push(d);
                    }
                })
            } catch(e) {
                console.error(e);
                this.error = e;
            } finally {
                this.loading = false;
            }
        },

        async createNewBrandSummary(event, filter) {
            if (this.creatingBrandSummary || !this.canEditNotifications) return;

            let title = null;
            let description = null;
            if (!filter) {
                const ownBrands = this.brands.filter(b => b.category === "OWN");
                const mainFilter = "published inthelast 24hours and relevancy isnt irrelevant and reshareof is unknown";
                if (ownBrands.length) {
                    title = "Weekly summary of your brands";
                    description = "This is a weekly email giving some high level stats of your own brands.";
                    filter = `(${mainFilter}) and (${ownBrands.map(b => `brand isorchildof ${b.id}`).join(' or ')})`
                } else {
                    filter = mainFilter
                }
            }

            this.creatingBrandSummary = {
                expanded: true,
                _new: true,
                active: true,
                type: "BRAND_SUMMARY",
                filter: filter,
                name: title,
                description: description,
                days: ['MON'],
                times: ['0700'],
                recipients: [this.user.email]
            };
            this.notifications.unshift(this.creatingBrandSummary)
        },

        async createNewPressDigest(event, filter) {
            if (this.creatingPressDigest || !this.canEditNotifications) return;

            let title = "Press summary";
            let description = "A weekly update of social media and traditional media from press sources.";
            filter = filter ?? "Relevancy ISNT IRRELEVANT AND Link DOESNTCONTAIN 'facebook.com' AND Link DOESNTCONTAIN 'instagram.com' AND Link DOESNTCONTAIN 'http://media-cache.brandseye.com' AND Link DOESNTCONTAIN 'pressdisplay.com' AND Link DOESNTCONTAIN 'video-cache.brandseye.com' AND Link DOESNTCONTAIN 'media-player.brandseye.com' AND Link DOESNTCONTAIN 'plus.google.com' AND Media IS PRESS";

            this.creatingPressDigest = {
                expanded: true,
                _new: true,
                active: true,
                type: "TRADITIONAL_MEDIA",
                filter: filter,
                name: title,
                description: description,
                days: ['MON'],
                times: ['0700'],
                recipients: [this.user.email]
            };
            this.notifications.unshift(this.creatingPressDigest)
        },

        createNewNotification(event, filter) {
            if (!filter) {
                const ownBrands = this.brands.filter(b => b.category === "OWN");
                const mainFilter = "published inthelast week and relevancy isnt irrelevant and reshareof is unknown";
                if (ownBrands.length) {
                    filter = `(${mainFilter}) and (${ownBrands.map(b => `brand isorchildof ${b.id}`).join(' or ')})`
                } else {
                    filter = mainFilter
                }
            }

            if (this.creating || !this.canEditNotifications) return;
            this.creating = {
                expanded: true,
                _new: true,
                active: true,
                type: "MENTION_DIGEST",
                filter: filter,
                days: ["MON"],
                times: ["0800"],
                name: null,
                description: null,
                recipients: [this.user.email],
                createdBy: this.user
            };
            this.notifications.unshift(this.creating)
        },

        async createNewVolumeSpike(event, filter) {
            // Alerts need to have a brand. See if we can
            // add a useful brand selection.
            if (!filter) {
                const brands = this.brands;
                const owned = brands.filter(b => b.category === "OWN");
                if (owned.length) {
                    const brandFilter = owned.map(b => `brand isorchildof ${b.id}`).join(' or ');
                    filter = `(${brandFilter}) and relevancy isnt irrelevant`;
                } else if (brands.length) {
                    filter = `brand isorchildof ${brands[0].id} and relevancy isnt irrelevant`;
                } else {
                    filter = "tag is 4";
                }
            }

            if (this.creatingSpike || !this.canEditNotifications) return;
            this.creatingSpike = {
                expanded: true,
                _new: true,
                active: true,
                type: "SPIKE_ALERT",
                filter: filter,
                name: null,
                description: null,
                recipients: []
            };
            this.notifications.unshift(this.creatingSpike)
        },

        spikeCreationCancelled() {
            if (this.creatingSpike) {
                const oldValue = this.creatingSpike;
                this.notifications = this.notifications.filter(n => n !== this.creatingSpike);
                this.creatingSpike = false;

                notifyWithText(
                    "Cancelled the creation of a new notification",
                    () => {
                        if (!this.creatingSpike) {
                            this.creatingSpike = oldValue;
                            this.notifications.push(this.creatingSpike);
                        }
                    }
                )
            }

        },

        spikeCreated(newSpike) {
            this.creatingSpike.id = newSpike.id;
            this.creatingSpike = false;

            this.updateNotificationFields({id: newSpike.id, expanded: true});

            this.$nextTick(() => {
                this.notifications.shift();
                this.notifications.unshift(newSpike);
                this.creatingSpike = false;
            });
        },

        brandSummaryCreationCancelled() {
            if (this.creatingBrandSummary) {
                const oldValue = this.creatingBrandSummary;
                this.notifications = this.notifications.filter(n => n !== this.creatingBrandSummary);
                this.creatingBrandSummary = false;

                notifyWithText(
                    "Cancelled the creation of a new weekly summary",
                    () => {
                        if (!this.creatingBrandSummary) {
                            this.creatingBrandSummary = oldValue;
                            this.notifications.unshift(this.creatingBrandSummary);
                        }
                    }
                )
            }
        },

        pressCreationCancelled() {
            if (this.creatingPressDigest) {
                const oldValue = this.creatingPressDigest;
                this.notifications = this.notifications.filter(n => n !== this.creatingPressDigest);
                this.creatingPressDigest = false;

                notifyWithText(
                    "Cancelled the creation of a new press summary",
                    () => {
                        if (!this.creatingPressDigest) {
                            this.creatingPressDigest = oldValue;
                            this.notifications.unshift(this.creatingPressDigest);
                        }
                    }
                )
            }
        },

        brandSummaryCreated(newSummary) {
            this.creatingBrandSummary.id = newSummary.id;
            this.creatingBrandSummary = false;

            this.updateNotificationFields({id: newSummary.id, expanded: true});

            this.$nextTick(() => {
                this.notifications.shift();
                this.notifications.unshift(newSummary);
                this.creatingBrandSummary = false;
            });
        },

        pressDigestCreated(newPressDigest) {
            this.creatingPressDigest.id = newPressDigest.id;
            this.creatingPressDigest = false;

            this.updateNotificationFields({id: newPressDigest.id, expanded: true});

            this.$nextTick(() => {
                this.notifications.shift();
                this.notifications.unshift(newPressDigest);
                this.creatingPressDigest = false;
            });
        },

        creationCancelled() {
            if (this.creating) {
                const oldValue = this.creating;
                this.notifications = this.notifications.filter(n => n !== this.creating);
                this.creating = false;

                notifyWithText(
                    "Cancelled the creation of a new notification",
                    () => {
                        if (!this.creating) {
                            this.creating = oldValue;
                            this.notifications.unshift(this.creating);
                        }
                    }
                )
            }
        },

        notificationCreated(createdNotification) {
            this.creating.id = createdNotification.id;

            this.updateNotificationFields({id: createdNotification.id, expanded: true});

            this.$nextTick(() => {
                this.notifications.shift();
                this.notifications.unshift(createdNotification);
                this.creating = false;
            });
        },

        isVisible(notification) {
            if (notification._new || notification._expanded) return true;
            if (this.hideInactive && !notification.active) return false;
            return !this.seeOnlySubscribed || this.isSubscribed(notification);
        },

        isSubscribed,

        /**
         * This is for data cleaning and back porting old alerts. It ensures that IDs are updated
         * as necessary and matched to existing filters if possible.
         */
        async updateAlertExternalId(digest) {
            if (!digest.externalId) return;

            const split = digest.externalId.split(':');
            if (split.length === 2) {
                // Updating to brandId:segmentId:rpcsTag
                const brands = getBrandsInFilter(digest.filter);
                if (brands.include.length === 0) {
                    // This is very old. Just delete them.
                    try {
                        await this.deleteDigest(digest.id);
                    } catch (e) {
                        console.warn(`There has been a problem deletingdigest ${digest.id}`);
                        console.warn(e);
                    }
                } else if (brands.include.length >= 1 && !brands.exclude.length) {
                    // Just an old external ID. Update that appropriately.
                    const old = digest.externalId;
                    digest.externalId = `${brands.include[0]}:${digest.externalId}`;
                    try {
                        await this.updateDigest(digest);
                    } catch (e) {
                        console.warn(`There has been a problem updating the external ID of digest ${digest.id}`);
                        console.warn(e);
                    }
                    console.info(`Updated ${digest.name} with external id ${old} to ${digest.externalId}`)
                } else {
                    this.error = "There has been a problem updating the default notifications. Please contact support.";
                    console.warn(`Digest ${digest.name}:${digest.id} cannot be updated from external id ${digest.externalId}`)
                }
            }
        },

        async deleteDefaults(defaults) {
            if (defaults.length) {
                console.group("Removing previous cancellation alerts");
                for (const d of defaults) {
                    d.active = false;
                    try {
                        console.warn(`Deleting default alert ${d.id}`)
                        await this.updateDigest(d);
                        await this.deleteDigest(d.id);
                    } catch(e) {
                        console.error(e);
                    }
                    d.deleted = true;
                }
                console.groupEnd();
            }
        },

        async checkForWarnings() {
            const regularWarnings = [];
            const summaryWarnings = [];
            const pressWarnings = [];
            for (const n of this.notifications) {
                if (!n.active) continue
                if (n.type !== "MENTION_DIGEST" && n.type !== "NOTIFICATION" && n.type !== "BRAND_SUMMARY" && n.type !== "TRADITIONAL_MEDIA") continue;

                const warningCount = (await getNotificationWarnings(n)).length;

                let list = regularWarnings;
                switch (n.type) {
                    case "BRAND_SUMMARY":
                        list = summaryWarnings;
                        break;
                    case "TRADITIONAL_MEDIA":
                        list = pressWarnings;
                        break;
                }

                if (warningCount > 0) {
                    list.push({
                        id: "HAS_WARNINGS",
                        message: `<strong>${escapeExpression(n.name)}</strong> has ${warningCount} ${formatPlural(warningCount, "warning")}`,
                        linkId: n.id
                    })
                }
            }

            this.regularWarnings = regularWarnings;
            this.brandSummaryWarnings = summaryWarnings;
            this.pressWarnings = pressWarnings;
        },

        navigateTo(warning, count) {
            if (warning.linkId) {
                const dom = document.querySelector(`[data-notification-id="${warning.linkId}"`);
                if (dom) dom.scrollIntoView({behavior: "smooth", block: "center"});
                else if (count >= 1) return;
                else {
                    this.hideInactive = false;
                    this.seeOnlySubscribed = false;
                    this.$nextTick(() => this.navigateTo(warning, 1));
                }
            }

        }
    }
}
</script>


<style scoped lang="sass">
    .notifications
        --border: 1px solid #1a1a1a
        --grid-columns: 1fr 130px 100px 150px

        @media (max-width: 800px)
            --grid-columns: 1fr 130px 100px 0


    .notifications-loaded
        max-width: 1200px
        margin-left: 25px
        margin-right: auto

    p
        color: var(--be-colour-text-dark)
        max-width: 750px

    strong
        color: white

    .notifications__spike-area
        position: relative
        display: grid
        grid-template-columns: repeat(auto-fill, 250px)
        grid-auto-rows: 350px
        grid-gap: 20px
        &--expanded ::v-deep .spike-notification:not(.expanded):not(:hover)
            opacity: 0.5

    .notifications__spike-area + .notifications__spike-area
        margin-top: 40px

    .notifications__controls
        display: flex
        margin-bottom: 5px
        & ::v-deep .btn-link
            padding: 0 0 5px

    .notifications__blank
        width: 50%

    .notifications__dotted-none
        width: 50%
        p
            margin: 10px 0
            color: var(--be-colour-text-dark)

    label
        color: var(--be-colour-muted-text-dark)
        display: flex
        align-items: flex-start
        &:not(:first-child)
            padding-left: 10px
        & > *
            padding-left: 5px

    .notifications__header
        display: grid
        grid-template-columns: var(--grid-columns)
        position: sticky
        top: 40px
        z-index: 10
        border: none
        color: var(--be-colour-text-dark)
        text-align: center
        span
            background: #222
            box-sizing: border-box
            border-top: var(--border)
            border-right: var(--border)
            padding: 5px 10px
            &:first-child
                border-top-left-radius: 3px
                border-left: var(--border)
            &:last-child
                border-top-right-radius: 3px

    .notifications__not-subscribed
        font-style: italic
        text-align: center
        border: thin solid #222
        color: var(--be-colour-text-dark)
        background: #333
        padding: 10px 0

    .notifications__section
        &:not(:first-of-type)
            margin-top: 60px

    .notifications--none
        height: 40%
        margin-top: auto
        margin-bottom: auto

    .warning-indicator
        display: inline-block
        position: relative
        top: -8px
        left: 5px
        & + span
            color: red

    .notifications__hide-small
        @media (max-width: 800px)
            display: none



</style>