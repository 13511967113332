import { render, staticRenderFns } from "./SetupNotifications.vue?vue&type=template&id=56a1b2b6&scoped=true"
import script from "./SetupNotifications.vue?vue&type=script&lang=js"
export * from "./SetupNotifications.vue?vue&type=script&lang=js"
import style0 from "./SetupNotifications.vue?vue&type=style&index=0&id=56a1b2b6&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56a1b2b6",
  null
  
)

export default component.exports